import React from "react";

import {
  Container,
  ColumnLayout,
  FormField,
  ButtonDropdown,
  Textarea,
  Button,
  SpaceBetween,
} from "@cloudscape-design/components";

import { feedbackRatingOptions } from "../../../utils/ChatMessageUtils";

// Define the props type for the FeedbackForm component
interface FeedbackFormProps {
  showReportFeedbacks: boolean;
  chatMessage: any;
  rating: string;
  feedbacks: string;
  onRatingChange: (rating: string) => void;
  onFeedbackChange: (feedbacks: string) => void;
  handleSubmit: () => void;
}

/**
 * FUnction to check if the feedback button should be disabled
 * @param feedbacks
 * @param rating
 */
function shouldDisableButton(feedbacks: string, rating: string): boolean {
  return !rating || (rating === "Other - please specify" && feedbacks === "");
}

/**
 * Returns the Feedback Form
 * @constructor
 * @param feedbackFormProps
 */
function FeedbackForm(feedbackFormProps: FeedbackFormProps) {
  /* istanbul ignore next */
  function handleRatingChange(selectedId: string) {
    const rating = selectedId === "none" ? "" : selectedId;
    feedbackFormProps.onRatingChange(rating);
  }

  if (!feedbackFormProps.showReportFeedbacks) return null;
  return (
    <Container>
      <ColumnLayout columns={2}>
        <div>
          <SpaceBetween size={"m"} direction={"horizontal"}>
            <FormField label="Rating" description="Examples: Accurate Answer, Inaccurate Answer, etc.">
              <ButtonDropdown items={feedbackRatingOptions} onItemClick={(e) => handleRatingChange(e?.detail?.id)}>
                {feedbackFormProps.rating !== "" ? feedbackFormProps.rating : "Please choose a rating"}
              </ButtonDropdown>
            </FormField>
            <FormField
              stretch={true}
              label="Feedbacks"
              description="If you are reporting an issue, you can describe the problem, and your expected outcome here."
            >
              <Textarea
                value={feedbackFormProps.feedbacks}
                placeholder="Please provide your feedbacks here"
                onChange={(e) => feedbackFormProps.onFeedbackChange(e?.detail?.value)}
              />
            </FormField>
            <Button
              variant="primary"
              disabled={shouldDisableButton(feedbackFormProps.feedbacks, feedbackFormProps.rating)}
              onClick={feedbackFormProps.handleSubmit}
            >
              Submit
            </Button>
          </SpaceBetween>
        </div>
      </ColumnLayout>
    </Container>
  );
}
export default FeedbackForm;
