import { Auth, Cache } from "aws-amplify";

// common constants among pages
export const USER_ALIAS_KEY = "webapp.user.login";
export const USER_EMAIL_KEY = "webapp.user.email";

export const JWT_TOKEN = "JWT_TOKEN";

/**
 * Configuring Amplify local cache.
 */
const amplifyCacheConfig = {
  keyPrefix: "FinneyAmplifyCache",
  defaultTTL: 6 * 60 * 60 * 1000, // 6 hours in milliseconds
};
const AmplifyLocalCache = Cache.createInstance(amplifyCacheConfig);

/**
 * Clear local cache when user signs out.
 */
export const signOut = () => {
  AmplifyLocalCache.clear();

  // sign out
  Auth.signOut();
};

/**
 * Example usage saveUserPref('lang', 'vi')
 * @param k example 'lang'
 * @param v example 'vi'
 */
export const saveUserPref = (k: string, v: string) => {
  /* istanbul ignore next */
  if (!(typeof v === "string")) {
    console.warn("warn from saveUserPref: v is expected of type string, but it is", typeof v);
    const _v = String(v); // defensive here, caller may not convert to this before hand
    console.log("I saved you the headache, and converted it to string:", _v);
    AmplifyLocalCache.setItem(`user_pref.${k}`, _v);
    return;
  }

  // normal happy path
  AmplifyLocalCache.setItem(`user_pref.${k}`, v);
};

/**
 * Example usage getUserPref('lang')
 * @param k example 'lang'
 */
export const getUserPref = (k: string): string => {
  return AmplifyLocalCache.getItem(`user_pref.${k}`);
};

/**
 * Returns user alias of authenticated user
 */
export const getUserAlias = (): string => {
  return getUserPref(USER_ALIAS_KEY);
};
