import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Auth } from "aws-amplify";

import { getUserPref, JWT_TOKEN } from "../amplify/AmplifyCache";
import { getStage } from "../amplify/AmplifyClientProvider";
import { getWebMetadataFeedbackEndpoint, REGION } from "../amplify/AmplifyConstants";

/**
 * Converts the isoTimestamp to local
 * @param isoTimestamp
 */
export function formatIsoTimestampToLocal(isoTimestamp: string) {
  const date = new Date(isoTimestamp);
  return date.toLocaleString();
}

/**
 * Dropdown rating options for negative feedback
 */
export const feedbackRatingOptions = [
  {
    text: "Answer is not factually correct",
    id: "Answer is not factually correct",
    disabled: false,
  },
  {
    text: "Answer does not address question",
    id: "Answer does not address question",
    disabled: false,
  },
  {
    text: "Answer is based on outdated info",
    id: "Answer is based on outdated info",
    disabled: false,
  },
  {
    text: "Incorrect skill detected",
    id: "Incorrect skill detected",
    disabled: false,
  },
  {
    text: "Other - please specify",
    id: "Other - please specify",
    disabled: false,
  },
];

/**
 * Function to handle feedbacks
 * @param action
 * @param messageId
 * @param rating
 * @param feedbacks
 */
/* istanbul ignore next */
export async function submitFeedbacks(action: string, messageId: string, rating: string, feedbacks?: string) {
  const metadataFeedbackHandlerEndpoint = getWebMetadataFeedbackEndpoint(getStage()); // appConfig.metadataEndpoint; // finney-longx account
  const url = new URL(metadataFeedbackHandlerEndpoint);

  // set up the HTTP request
  const request = new HttpRequest({
    hostname: url.hostname,
    path: url.pathname,
    body: JSON.stringify({
      action: action,
      messageId: messageId,
      rating: rating,
      feedback: feedbacks,
    }),
    method: "POST",
    headers: {
      jwtToken: getUserPref(JWT_TOKEN),
      "Content-Type": "application/json",
      host: url.hostname,
    },
  });
  // create a signer object with the credentials, the service name and the region
  // set up the url request to be signed for sigv4
  const _currentCreds = await Auth.currentUserCredentials();
  const signer = new SignatureV4({
    credentials: _currentCreds,
    service: "lambda",
    region: REGION,
    sha256: Sha256,
  });

  // sign the request and extract the signed headers, body and method
  const { headers, body, method } = await signer.sign(request);

  const response = await fetch(url, {
    method: method,
    headers: headers,
    body: body,
    mode: "cors",
    cache: "no-cache",
  });

  return await response.json();
}
