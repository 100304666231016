import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home page="Home" />} />
        <Route path="/skills/:skillId" element={<Home page="Skill" />} />
      </Routes>
    </BrowserRouter>
  );
}
