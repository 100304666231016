import { Auth } from "aws-amplify";

import {
  getCognitoDomain,
  getIdentityPoolId,
  getRedirectUrl,
  getUserPoolId,
  getUserPoolWebClientId,
  REGION,
} from "./AmplifyConstants";

export const getStage = () => {
  let hostName = window.location.hostname;
  return hostName.substring(hostName.indexOf("https://") + 1, hostName.lastIndexOf(".finney.finergy.amazon.dev"));
};

/**
 * Convenient utils to use Amplify.Auth
 */
export class AmplifyClientProvider {
  /**
   * Init Amplify Auth Config.
   */
  static initialize(): void {
    const oauth = {
      domain: getCognitoDomain(getStage()),
      scope: ["openid"],
      redirectSignIn: getRedirectUrl(getStage()),
      redirectSignOut: getRedirectUrl(getStage()),
      responseType: "code",
    };

    Auth.configure({
      Auth: {
        identityPoolId: getIdentityPoolId(getStage()),
        region: REGION,
        userPoolId: getUserPoolId(getStage()),
        userPoolWebClientId: getUserPoolWebClientId(getStage()),
        mandatorySignIn: true,
        oauth: oauth,
        cookieStorage: {
          // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: `${window.location.hostname}`,
          // OPTIONAL - Cookie path
          path: "/",
          // OPTIONAL - Cookie expiration in days
          expires: 365,
          // OPTIONAL - Cookie secure flag
          secure: true,
        },
      },
    });
  }

  /**
   * Try to authenticate user.
   * Then return CognitoUser obj if all good.
   */
  public static async authenticateUser() {
    return await Auth.currentAuthenticatedUser()
      .then((user: any) => {
        return Promise.resolve(user);
      })
      .catch(() => {
        // Invoke Cognito OpenId Federated auth flow.
        // We use AmazonFederate->Midway on backend.
        // It will take user to Midway sign in screen
        // then redirect back to homepage once sign in successfully.
        Auth.federatedSignIn({ customProvider: "AmazonFederate" });

        return Promise.resolve(null);
      });
  }

  /**
   * return JWT Token obj if all good.
   */
  public static async getJWTToken() {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }
}
