import { SetStateAction } from "react";

/**
 * Function to read chunks of data from the Orchestrator
 * @param response
 * @param chatPanelDom
 * @param debugMessagesDom
 * @param setChatPanelContents
 * @param addToDebugMessages
 * @param autoSpeak
 */
export const readChunks = async (
  response: Response,
  chatPanelDom: HTMLElement,
  debugMessagesDom: HTMLElement,
  setChatPanelContents: {
    (value: SetStateAction<string>): void;
    (content: string): void;
  },
  addToDebugMessages: { (date: string): void; (trace: string): void },
  autoSpeak: boolean
) => {
  let liveRenderingFinneyAnswerContents = ""; // render the answer as a stream
  let finalFinneyAnswerChunks = []; // used to only capture LLM answer text
  let referencedSources = [];
  let messageId;
  let _conversationId;
  let smartRoutingDetectedSkill;
  // @ts-ignore
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let result = await reader.read();
  let buffer = "";
  while (!result.done) {
    let chunkStringifiedJsonsStr = "";
    try {
      chunkStringifiedJsonsStr = decoder.decode(result.value, { stream: true });
      const chunkStringifiedJsonSplitMarkerStr = chunkStringifiedJsonsStr.replaceAll("}{", "}JSON_SPLIT_MARKER{");
      const chunkStringifiedJsons = chunkStringifiedJsonSplitMarkerStr.split("JSON_SPLIT_MARKER");
      const firstChunk = chunkStringifiedJsons[0];
      const lastChunk = chunkStringifiedJsons[chunkStringifiedJsons.length - 1];
      if (!lastChunk.endsWith("}")) {
        buffer = lastChunk;
        chunkStringifiedJsons.pop();
      }
      if (!firstChunk.startsWith("{")) {
        buffer += firstChunk;
        chunkStringifiedJsons.shift();
      }
      for (const chunkStringifiedJson of chunkStringifiedJsons) {
        let chunkObj;
        if (buffer && buffer.endsWith("}") && buffer.startsWith("{")) {
          chunkObj = JSON.parse(buffer);
          buffer = "";
        } else {
          chunkObj = JSON.parse(chunkStringifiedJson);
        }
        switch (chunkObj.chunkType) {
          case "messageStart":
            break;
          case "messageSetControlMetadata":
            const controlMetadataObj = chunkObj.controlMetadata;
            if ("messageId" === controlMetadataObj.key) {
              messageId = controlMetadataObj.value;
            }
            if ("conversationId" === controlMetadataObj.key) {
              _conversationId = controlMetadataObj.value;
            }
            if ("smartRoutingDetectedSkill" === controlMetadataObj.key) {
              smartRoutingDetectedSkill = controlMetadataObj.value;
            }
            break;
          case "messageContentDelta":
            if (chunkObj.messageContentType === "text/plain") {
              liveRenderingFinneyAnswerContents += chunkObj.messageContentDelta;
              finalFinneyAnswerChunks.push(chunkObj.messageContentDelta);
              setChatPanelContents((prev) => prev + liveRenderingFinneyAnswerContents);
            } else {
              const imgBase64 = chunkObj.messageContentDelta;
              const img = new Image();
              img.src = imgBase64;
              liveRenderingFinneyAnswerContents += `<img src="${img.src}" alt="Finney"/>`;
              finalFinneyAnswerChunks.push(imgBase64);
              setChatPanelContents((prev) => prev + liveRenderingFinneyAnswerContents);
            }
            break;
          case "messageReferencedSources":
            if (chunkObj.messageReferencedType === "text/plain") {
              referencedSources = chunkObj.messageReferencedSources;
            } else {
              console.warn("Un-anticipated messageCitations.messageCitationType", chunkObj);
            }
            break;
          case "messageTrace":
            addToDebugMessages(`${new Date().toLocaleString()} - SERVER - TRACE - ${chunkObj.trace}`);
            break;
          case "messageError":
            console.error("messageError", chunkObj.error);
            const errorMsg = `\n\n${chunkObj.error}`;
            liveRenderingFinneyAnswerContents += errorMsg;
            finalFinneyAnswerChunks.push(errorMsg);
            setChatPanelContents((prev) => prev + liveRenderingFinneyAnswerContents);
            addToDebugMessages(`${new Date().toLocaleString()} - SERVER - ERROR - ${chunkObj.error}`);
            break;
          case "messageStop":
            break;
          case "returnControl":
            if (autoSpeak) {
              let msgToRead = finalFinneyAnswerChunks.join("");
              if (msgToRead.includes("=== Answer: ")) {
                msgToRead = msgToRead.split("=== Answer: ")[1];
              }
              const utterance = new SpeechSynthesisUtterance(msgToRead);
              speechSynthesis.speak(utterance);
            }
            break;
          default:
            console.warn("unknown message type for response streaming, chunkObj is", chunkObj);
            break;
        }
      }
    } catch (error) {
      console.error("error", error, "happend when handling chunk", chunkStringifiedJsonsStr);
    }
    result = await reader.read();
    // auto scroll to bottom
    chatPanelDom.scrollTop = chatPanelDom.scrollHeight;
    debugMessagesDom.scrollTop = debugMessagesDom.scrollHeight;
  }
  // auto scroll to bottom
  chatPanelDom.scrollTop = chatPanelDom.scrollHeight;
  setChatPanelContents(""); // Important: clean chatPanelContents so that the message streaming box is gone
  return {
    messageId,
    _conversationId,
    finalAnswer: finalFinneyAnswerChunks.join(""),
    referencedSources,
    smartRoutingDetectedSkill,
  };
};
